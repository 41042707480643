import {addressOrderTime} from "@/Api/ShopAdmin/index";
import Storage from "@/util/Storage"
import { navList } from "@/views/ShopAdmin/Distance/Model/index";

export function getOrderAreaTime(areaId:string|number) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(addressOrderTime({userId,token},{ areaId }).then(res=>{
        let data = { ...res.data,stateList:[],orderList:[] }
        navList.length && navList.forEach((item,index)=>{
            if ( item.state ){
                item.num = res.data[item.state]
            }else{
                item.num = 0
            }
        })
        let List:any[] = []
        res.data.storeOrderTime.length && res.data.storeOrderTime.forEach((item:any,index:number)=>{
            List.push([
                index + 1,
                item.storeName || '',
                item.time15 || 0,
                item.time30 || 0,
                item.time45 || 0,
                item.maxTime || 0
            ])
        })
        data.stateList = navList
        data.orderList = List
        return data
    }))
}
