import {StateType} from "@/views/ShopAdmin/Distance/Model/indexType";


export const navList:StateType[] = [
    {
        value:"序号",
        num:0,
        state:""
    },{
        value:"区域",
        num:0,
        state:""
    },{
        value:"0-15/m",
        num:28,
        state:"totalTime15"
    },{
        value:"15-30/m",
        num:36,
        state:"totalTime30"
    },{
        value:"30-45/m",
        num:16,
        state:"totalTime45"
    },{
        value:"45-60/m",
        num:6,
        state:"totalMaxTime"
    },
]
